import React, { Component } from 'react';
import fiber from '../assets/fiber.jpg';
import people from '../assets/people.jpg';
import coffeecup from '../assets/cup-of-coffee.jpg';
import skyscrapers from '../assets/skyscrapers.jpg';

export class AdvisoryBranch extends Component {
  render() {
    return (
      <section className='SectionWhatWeOffer'>
        <h1>What we offer</h1>
        <div className='container-1-WhatWeOffer'>
        <div className="vertical-line-WhatWeOffer"></div>
           <div className='container-information'>
              <h1>Flexible acquisition</h1>
              <p>To maximize opportunities in today’s complex landscape, leaders need advice and judgment from anexperienced partner at the forefront of international markets and industries.</p>
            </div>
            <div className="vertical-line-WhatWeOffer"></div>
            <div className='container-information'>
              <h1>Debt consolidation</h1>
              <p>Our vast network across Europe, North America, LATAM, and China, allows us to connect our clients with potential targets or partners across 4 different continents.</p>
            </div>
        </div>
        <div className='container-1-WhatWeOffer'>
           <div className="vertical-line-WhatWeOffer"></div>
            <div className='container-information'>
                <h1>Recapitalisation</h1>
                <p>En nuestra empresa, nos dedicamos a ofrecer soluciones tecnológicas personalizadas para cada cliente. Ya sea que necesites una página web moderna o una aplicación intuitiva, nuestro equipo de expertos está listo para ayudarte a llevar tu negocio al siguiente nivel. Innovamos con pasión, garantizando resultados eficientes y de alta calidad.</p>
            </div>
          </div>

      </section>
    )
  }
}

export default AdvisoryBranch