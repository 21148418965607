import './App.css';
import ViewAboutUs from './Components/ViewAboutUs';
import NavBarComponent from './Components/NavBarComponent';
import ViewTeam from './Components/ViewTeam';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ViewContact from './Components/ViewContact'; // Asegúrate de que esté bien importado
import ViewOurAdvisoryBranch from './Components/ViewOurAdvisoryBranch';

function App() {
  return (
    <div className="App">
        <Router>
            <NavBarComponent></NavBarComponent>
            <Routes>
              <Route path="/" element={<ViewAboutUs/>} />
              <Route path="/our-advisory-branch" element={<ViewOurAdvisoryBranch/>} />
              <Route path="/team" element={<ViewTeam/>} />
              <Route path='/contact' element={<ViewContact/>}/>
            </Routes>
        </Router>
      </div>
  );
}

export default App;
