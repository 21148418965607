import React, { Component } from 'react';
import CountUp from 'react-countup';

export class OurInvesmentStrategy extends Component {
    render() {
        return (
         <section className='OurInvesmentStrategy'>
            <div className='OurInvestmentStrategyTitle'>
                <h1>Our investment strategy</h1>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className='SectionInvestmentInformation-Sections-container-left'>
                        <div className="circle">
                            <span>1</span>
                        </div>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>Companies making 500k to 5M in annual revenue</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className="circle">
                        <span>2</span>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>30% profit margins</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className='SectionInvestmentInformation-Sections-container-left'>
                        <div className="circle">
                            <span>3</span>
                        </div>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>Non digitalised or non internationalised</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className='SectionInvestmentInformation-Sections-container-left'>
                        <div className="circle">
                            <span>4</span>
                        </div>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>In a scalable market</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className='SectionInvestmentInformation-Sections-container-left'>
                        <div className="circle">
                            <span>5</span>
                        </div>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>In business at least five years, with a minimum of three years of prior financial statements</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
            <div className='containers-SectionInvestmentInformation'>
                <div className='SectionInvestmentInformation-Sections'>
                    <div className='SectionInvestmentInformation-Sections-container-left'>
                        <div className="circle">
                            <span>6</span>
                        </div>
                    </div>
                    <div className='SectionInvestmentInformation-Sections-container-right'>
                        <div>
                            <h4>Principal operations in Spain, we will selectively consider transactions outside of Spain</h4>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
         </section>
        )
      }
    
}

export default OurInvesmentStrategy