import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


export const Footer = () => {
  return (
     <section className='Footer'>
        <h3>Responsible management is an integral part of our identity.</h3>
        <div className='FooterColumns'>
          <div className='FooterMenu'>
              <h4>DISCOVER</h4>
              <p>About Us</p>
              <p>Team</p>
              <p>Contact</p>
          </div>
          <div className='FooterContact'>
              <h4>CONTACT</h4>
              <p>Carrer Tuset 23-25, 1o 8a</p>
              <p>Barcelona, Barcelona, Espanya</p>
              <p>+34 615 64 86 26</p>
              <p>m.riba.ribrodcapital@gmail.com</p>
          </div>  
          <div className='FooterSocial'>
            <h4>FOLLOW US</h4>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
        </div>  
     </section>
  )
}
export default Footer;