
import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100vw', // 100% del ancho de la pantalla
  height: '50vh', // 100% del alto de la pantalla
};

const apiKey = 'TU_API_KEY';

// Coordenadas de la empresa
const posicionEmpresa = {
  lat: 40.416775, // Latitud (ejemplo: Madrid, España)
  lng: -3.703790, // Longitud
};





const ContactPage = () => {
  return (
    <div>
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={posicionEmpresa}
        zoom={15}
      >
        {/* Marcador de la empresa */}
        <Marker position={posicionEmpresa} />
      </GoogleMap>
    </LoadScript>
    </div>

  );
};

export default ContactPage;
