import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box, IconButton } from '@mui/material';
import carolina from '../assets/carolina.jpeg';
import gerarddelucas from '../assets/gerarddelucas.PNG';
import julia from '../assets/julia.jpeg';
import marc from '../assets/marc.jpeg';
import MarkMyhew from '../assets/MarkMyhew.jpeg';
import quim from '../assets/quim.jpeg';

const TeamMembers = [
  {
    name: 'Marc Riba',
    role: 'Founder & CEO',
    image: marc,
    linkedin: 'https://www.linkedin.com/in/marc-riba', // Añade el enlace de LinkedIn
  },
  {
    name: 'Gerard de Lucas',
    role: 'Legal Counsel',
    image: gerarddelucas,
    linkedin: 'https://www.linkedin.com/in/gerard-de-lucas', // Añade el enlace de LinkedIn
  },
  {
    name: 'Mark Mayhew',
    role: 'Board Member & Strategic Advisor',
    image: MarkMyhew,
    linkedin: 'https://www.linkedin.com/in/mark-mayhew', // Añade el enlace de LinkedIn
  },
  {
    name: 'Quim Valls',
    role: 'Chief Financial Officer',
    image: quim,
    linkedin: 'https://www.linkedin.com/in/quim-valls', // Añade el enlace de LinkedIn
  },
  {
    name: 'Júlia Bacaria',
    role: 'Data Protection Officer',
    image: julia,
    linkedin: 'https://www.linkedin.com/in/julia-bacaria', // Añade el enlace de LinkedIn
  },
  {
    name: 'Carolina Andrade',
    role: 'PR & Communications Director',
    image: carolina,
    linkedin: 'https://www.linkedin.com/in/carolina-andrade', // Añade el enlace de LinkedIn
  },
];

const TeamSection = () => {
  return (
    <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
      <Container>
        <Typography variant="h3" align="center" gutterBottom>
          MEET OUR TEAM
        </Typography>
        <Grid container spacing={4}>
          {TeamMembers.map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, mx: 'auto', boxShadow: 3, transition: '0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                <CardMedia
                  component="img"
                  alt={member.name}
                  height="300"
                  image={member.image}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography variant="h5" component="div" align="center">
                    {member.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" align="center">
                    {member.role}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TeamSection;
