// Hamburguer.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Hamburguer = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="hamburger-menu">
      {/* Icono de hamburguesa */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        &#9776;
      </div>

      {/* Menú que se abrirá al hacer clic */}
      <nav className={`menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/our-advisory-branch" onClick={toggleMenu}>Our Advisory Branch</NavLink>
          </li>
          <li>
            <NavLink to="/team" onClick={toggleMenu}>Team</NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={toggleMenu}>Contact</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Hamburguer;
