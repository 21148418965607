import { NavLink } from 'react-router-dom';
import ribrodlogo from '../assets/logoribrod.jpeg';
import Hamburguer from './Hamburguer';

export const NavBarComponent = () => {
  return (
    <nav className="navbar">
      <div>
        <img className='logo_ribrod' src={ribrodlogo}></img>
      </div>
      <div>
        <Hamburguer></Hamburguer>
      </div>
    </nav>
  );
}

export default NavBarComponent;

