import React, { useState } from "react";

const OurPrinciples = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [fade, setFade] = useState(false);

  const sections = [
    { title: "Creating enterprise value", description: "We open companies to international markets such as the US, Canada, UK and Brazil." },
    { title: "Relationship driven", description: "We have a strong network in these massive markets to identify partnerships, grow sales and overall expand the company’s business operations" },
    { title: "Innovation", description: "We invest in founders and teams that are looking to the future. We focus on innovative ideas and processes for our portfolio companies and clients" },
    { title: "Growth", description: "We help our companies reach their full potential by advising them on organic and inorganic growth" }
  ];

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentSection((prevSection) =>
        prevSection === sections.length - 1 ? 0 : prevSection + 1
      );
      setFade(false);
    }, 300);
  };

  const handlePrevious = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentSection((prevSection) =>
        prevSection === 0 ? sections.length - 1 : prevSection - 1
      );
      setFade(false);
    }, 300);
  };

  return (
    <section className="section-ourprinciples">
        <h1 class="title">Our principles</h1>
      <div className="ourprinciples">
        <div className={`ourprinciples-left ${fade ? 'fade' : ''}`}>
          <h1>{sections[currentSection].title}</h1>
        </div>
        <div className="vertical-line"></div>
        <div className={`ourprinciples-right ${fade ? 'fade' : ''}`}>
          <p>{sections[currentSection].description}</p>
        </div>
      </div>
      
      <div className="button-container">
        {/* Botón personalizado izquierdo */}
        <button className="custom-button left-button" onClick={handlePrevious}>
          &#8592; {/* Flecha izquierda */}
        </button>

        {/* Botón personalizado derecho */}
        <button className="custom-button right-button" onClick={handleNext}>
          &#8594; {/* Flecha derecha */}
        </button>
      </div>
    </section>
  );
};

export default OurPrinciples;
